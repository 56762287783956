import React from 'react'
import { Route } from 'react-router-dom'
import Personal from './personal/Personal'
import Downloader from './downloader/Downloader'

const routes = [
    <Route exact path="/personal" render={() => <Personal />} />,
    <Route exact path="/downloader" render={() => <Downloader />} />
]

export default routes
