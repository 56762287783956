import React, { useState, useEffect } from 'react';
import { TextInput, Title, SimpleForm, useTranslate, useNotify, useRedirect } from 'react-admin';
import { Box, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, FormControl, InputLabel, Select, MenuItem, Grid, Tabs, Tab, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import jsonServerProvider from 'ra-data-json-server'
import httpClient from '../dataProvider/httpClient'
import { REST_URL } from '../consts'
import { useLocation, useHistory } from 'react-router-dom';

// Define your client ID and secret
const CLIENT_ID = '9a26e2e7e9b04a888770a7a0f81fe471';
const CLIENT_SECRET = '18e1237029b14ce596e069d88cdf46b2';

const useStyles = makeStyles({
  root: { marginTop: '1em' },
  resultContainer: { marginTop: '1em' },
  coverImage: { maxWidth: '100px', cursor: 'pointer' }, // Add cursor pointer to show it's clickable
  formControl: { minWidth: 120 },
  textarea: { width: '100%', minHeight: '200px', padding: '10px', marginTop: '20px' }
});

const Downloader = ({ accessToken }) => {
  const classes = useStyles();
  const [spotifySearchResults, setSpotifySearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [addedToQueue, setAddedToQueue] = useState({});
  const [searchText, setSearchText] = useState('');
  const [artist, setArtist] = useState('');
  const [type, setType] = useState('album');
  const location = useLocation();
  const history = useHistory();
  const [showTable, setShowTable] = useState(false);
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [statusContent, setStatusContent] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const searchQuery = query.get('searchText');
    const artistQuery = query.get('artist');
    const typeQuery = query.get('type');

    if (searchQuery) {
      setSearchText(searchQuery);
    }
    if (artistQuery) {
      setArtist(artistQuery);
    }
    if (typeQuery) {
      setType(typeQuery);
    }

    if (searchQuery || artistQuery || typeQuery) {
      handleSubmit(searchQuery, artistQuery, typeQuery, true);
    }
  }, [location]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit(searchText, artist, type);
      }
    };

    document.getElementById('searchText').addEventListener('keypress', handleKeyPress);

    return () => {
      document.getElementById('searchText').removeEventListener('keypress', handleKeyPress);
    };
  }, [searchText, artist, type]);

  useEffect(() => {
    let statusInterval;
    if (selectedTab === 1) {
      // Load status content initially
      getStatusContent();

      // Refresh status content every 5 seconds
      statusInterval = setInterval(() => {
        getStatusContent();
      }, 5000);
    }

    return () => {
      clearInterval(statusInterval);
    };
  }, [selectedTab]);

  const getAccessToken = async () => {
    const url = 'https://accounts.spotify.com/api/token';
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET),
    };
    const body = 'grant_type=client_credentials';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body
      });
      const data = await response.json();
      return data.access_token;
    } catch (error) {
      console.error('Error fetching access token:', error);
      throw new Error('Failed to fetch access token');
    }
  };

  const searchSpotify = async (searchText, type, artist, offset) => {
    if (!searchText && !artist) {
      setErrorMessage('Please enter a search query');
      return;
    }

    const token = await getAccessToken();
    const encodedSearchText = encodeURIComponent(searchText || '');
    const encodedArtist = encodeURIComponent(artist || '');
    const apiUrl = `https://api.spotify.com/v1/search?q=${encodedSearchText}${artist ? '%20artist:' + encodedArtist : ''}&type=${type}&limit=${limit}&offset=${offset}`;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    try {
      const response = await fetch(apiUrl, { headers });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSpotifySearchResults(prevResults => [...prevResults, ...data[type + 's'].items]);
      setErrorMessage('');
      setShowTable(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data');
      setShowTable(false);
    }
  };

  const getStatusContent = async () => {
    try {
      const response = await httpClient(`${REST_URL}/downloader/status`, {
        method: 'GET',
      });
      const data = await response;
      setStatusContent(data.json.downloaderOutput);
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  };
  

  const handleSubmit = (searchText, artist, type, resetAfter = false) => {
    if (!searchText && !artist) {
      setErrorMessage('Please enter a search query');
      return;
    }
    setSearchText(searchText);
    setOffset(0);
    setSpotifySearchResults([]);
    searchSpotify(searchText, type, artist, 0);

    if (resetAfter) {
      setSearchText('');
      setArtist('');
    }
  };

  const handleDownload = async (spotifyUrl, id) => {
    try {
      const url = `${REST_URL}/downloader?spotifyUrl=${encodeURIComponent(spotifyUrl)}`;
      const response = await httpClient(url, {
        method: 'POST',
      });
      const { json } = response;
      setAddedToQueue(prevState => ({
        ...prevState,
        [id]: true,
      }));
      // Process response data as needed
    } catch (error) {
      console.error('Error downloading:', error);
    }
  };

  const handleLoadMore = () => {
    setOffset(offset + limit);
    searchSpotify(searchText, type, artist, offset + limit);
  };

  return (
    <Card className={classes.root}>
      <Title title={'Navidrome - ' + 'Downloader'} />
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
        aria-label="Downloader Tabs"
      >
        <Tab label="Search" />
        <Tab label="Status" />
      </Tabs>
      {selectedTab === 0 && (
        <>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid item>
              <SimpleForm toolbar={false}>
                <TextInput
                  source="searchText"
                  id="searchText"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  defaultValue={searchText}
                />
              </SimpleForm>
            </Grid>
            <Grid item>
              {type === 'album' &&
                <SimpleForm toolbar={false}>
                  <TextInput
                    source="artist"
                    id="artist"
                    value={artist}
                    onChange={(e) => setArtist(e.target.value)}
                    defaultValue={artist}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(searchText, artist, type);
                      }
                    }}
                  />
                </SimpleForm>
              }
            </Grid>
            <Grid item>
              <Box mt={3}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="type-select-label">Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={type}
                    disabled={true}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value="album">Album</MenuItem>
                    <MenuItem value="playlist">Playlist</MenuItem>
                    <MenuItem value="track">Track</MenuItem>
                    <MenuItem value="show">Show</MenuItem>
                    <MenuItem value="episode">Episode</MenuItem>
                    <MenuItem value="audiobook">Audiobook</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box ml={3} mt={0} mb={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(searchText, artist, type)}
                  disabled={!searchText && !artist}
                >
                  Search
                </Button>
              </Box>
            </Grid>
          </Grid>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          {showTable && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cover</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Artist</TableCell>
                      {type === 'album' && <TableCell>Album Type</TableCell>}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {spotifySearchResults.map(item => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <a href={item.external_urls.spotify} target="_blank" rel="noopener noreferrer">
                            <img src={item.images[0].url} alt="Album Cover" className={classes.coverImage} />
                          </a>
                        </TableCell>
                        <TableCell>{type === 'album' ? item.name : item.name}</TableCell>
                        <TableCell>{type === 'album' ? item.artists[0].name : 'N/A'}</TableCell>
                        {type === 'album' && <TableCell>{item.album_type}</TableCell>}
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDownload(item.external_urls.spotify, item.id)}
                            disabled={addedToQueue[item.id]}
                          >
                            {addedToQueue[item.id] ? 'Added' : 'Add to queue'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={3} ml={3} mb={3} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadMore}
                >
                  Load More
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {selectedTab === 1 && (
        <>
          <TextareaAutosize
            className={classes.textarea}
            value={statusContent}
            readOnly
          />
        </>
      )}
    </Card>
  );
};

export default Downloader;
