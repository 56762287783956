import React, { forwardRef } from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import { makeStyles, Badge } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  badge: {
    top: 12,
    right: 4,
    width: 20,
    height: 20,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: 12,
    fontWeight: 'bold',
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

const DownloaderMenu = forwardRef(({ onClick, sidebarIsOpen, dense, badge }, ref) => {
  const classes = useStyles();

  return (
    <MenuItemLink
      ref={ref}
      to="/downloader"
      primaryText='Downloader'
      leftIcon={
        badge ? (
          <Badge badgeContent={badge} classes={{ badge: classes.badge }}>
            <DownloadIcon />
          </Badge>
        ) : (
          <DownloadIcon />
        )
      }
      onClick={onClick}
      className={classes.menuItem}
      sidebarIsOpen={sidebarIsOpen}
      dense={dense}
    />
  );
});

export default DownloaderMenu;
